<template>
  <div id="app-container">
    <component :is="layout"></component>
  </div>
</template>

<script>
import LayoutDefault from './layouts/Default'
import LayoutUnderConstruction from './layouts/UnderConstruction'
import LayoutUIKit from './layouts/UIKit'
import { mapGetters } from 'vuex'
import * as Sentry from '@sentry/vue'
import {
  setOptions as gTagSetOptions,
  bootstrap as gTagBootstrap,
} from 'vue-gtag'

import GoogleTagManager from './classes/GoogleTagManager'

export default {
  components: {
    'layout-default': LayoutDefault,
    'layout-under-construction-page': LayoutUnderConstruction,
    'layout-empty': LayoutUIKit,
  },
  props: {
    isProjectUnderConstruction: {
      type: Boolean,
      default: undefined,
    },
    isProjectDataAvailable: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      projectColorPallete: 'Project/projectColorPallete',
      htmlTitle: 'Project/htmlTitle',
      faviconUrl: 'Project/faviconUrl',
      fontFamily: 'Project/fontFamily',
      facebookPixelId: 'Project/facebookPixelId',
      googleTagManagerId: 'Project/googleTagManagerId',
      gTagOptions: 'Project/gTagOptions',
      isOfflineModeEnabled: 'Project/isOfflineModeEnabled',
      projectRootElement: 'Topview/project',
    }),
    layout() {
      if (!this.isProjectDataAvailable) {
        return 'layout-under-construction-page'
      }
      const { layout } = this.$route.meta
      if (this.$options.components[layout] !== undefined) {
        return layout
      } else {
        throw new Error(`Layout on route ${this.$route.name} not defined`)
      }
    },
  },
  watch: {
    fontFamily() {
      document.body.style.setProperty('--font-family', this.fontFamily)
    },
    $route(to, from) {
      this.$navigationHistoryManager.handleHistoryStep(from, to)
    },
  },
  async created() {
    this.loadTitleFromLocalStorage()
    this.setViewportHeightVariable()

    if (this.isProjectUnderConstruction) {
      try {
        await this.disableSentry()
        this.$loggingService.logInfo(
          'Sentry disabled due to project being under construction'
        )
      } catch (err) {
        this.$loggingService.logError(
          `Error while checking if project is under construction in order to determine if sentry should be disabled: ${err.toString()}`
        )
      }
    }

    // Set the primary color and title of the app
    this.setPrimaryColor()
    this.setTitle(this.htmlTitle)
    this.setTitleMetaTag(this.htmlTitle)
    this.storeTitleInLocalStorage(this.htmlTitle)
    if (this.isOfflineModeEnabled) {
      this.$loggingService.logInfo(
        'Google Analytics not enabled: Offline Mode is enabled'
      )
    } else {
      this.enableGoogleAnalytics(this.gTagOptions)
    }
    this.loadFacebookPixelIfAvailable()
    this.setGoogleTagManagerIfAvailable()
    this.setLangAttribute(this.$i18n.locale)
    this.setTopbarOffset()

    this.initNavigationHistoryManager()
  },
  mounted() {
    window.addEventListener('resize', this.setViewportHeightVariable)
    window.addEventListener('orientationchange', this.setViewportHeightVariable)
    document.addEventListener('touchstart', this.preventTouchGestureZoom, {
      passive: false,
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setViewportHeightVariable)
    window.removeEventListener(
      'orientationchange',
      this.setViewportHeightVariable
    )
    document.body.removeEventListener(
      'touchstart',
      this.preventTouchGestureZoom
    )
  },
  methods: {
    /**
     * Load title from localStorage
     * @return {void}
     */
    loadTitleFromLocalStorage() {
      let title = localStorage.getItem('title')
      if (title !== null) {
        this.setTitle(title)
        this.setTitleMetaTag(title)
      }
    },

    /**
     * Set the title for the HTML document
     * @param {string} title - the value of the title
     */
    setTitle(title) {
      document.title = title
    },

    /**
     * Set the content of the meta tag "title"
     * @param {string} title - the value of the title
     */
    setTitleMetaTag(title) {
      const metaTitle = document.querySelector('meta[name="title"]')
      if (metaTitle) {
        metaTitle.content = title
      }
    },

    /**
     * Store title in localStorage
     * @param {string} title - value of the title
     */
    storeTitleInLocalStorage(title) {
      localStorage.setItem('title', title)
    },

    /**
     * Set the value of the lang attribute of "html" tag
     * @param {string} languageCode - the value of the lang
     */
    setLangAttribute(languageCode) {
      document.documentElement.lang = languageCode
    },

    /**
     * Set the CSS vars for the project color pallete used in the webapp
     */
    setPrimaryColor() {
      document.body.style.setProperty(
        '--primary-color',
        this.projectColorPallete.regular
      )
      this.projectColorPallete.variantIds.forEach((variantId) => {
        document.body.style.setProperty(
          `--primary-color-${variantId}`,
          this.projectColorPallete.variant(variantId)
        )
      })
    },
    calcViewportHeight() {
      return window.innerHeight
    },
    setViewportHeightVariable() {
      const vh = this.calcViewportHeight() / 100
      document.querySelector(':root').style.setProperty('--vh', `${vh}px`)
    },
    setTopbarOffset() {
      const { extraordinaryMenuButtonOffset } = this.$route.query
      const offset = parseInt(extraordinaryMenuButtonOffset, 10) || 0
      document
        .querySelector(':root')
        .style.setProperty('--menu-offset', `${offset}px`)
    },
    async disableSentry() {
      await Sentry.close(1)
    },
    async enableGoogleAnalytics(gTagOptions) {
      try {
        if (gTagOptions) {
          gTagSetOptions(gTagOptions)
          await gTagBootstrap()
        } else {
          this.$loggingService.logInfo('Google Analytics was not configured')
        }
      } catch (error) {
        this.$loggingService.logError(
          `Error while initializing Google Analytics: options: ${JSON.stringify(
            gTagOptions
          )}. Error: ${error.toString()}`
        )
      }
    },
    loadFacebookPixelIfAvailable() {
      if (this.facebookPixelId !== null) {
        try {
          this.$analytics.fbq.init(this.facebookPixelId)
        } catch (error) {
          this.$loggingService.logError(
            `Error while initializing Facebook Pixel: Id: ${
              this.facebookPixelId
            }. Error: ${error.toString()}`
          )
        }
      }
    },
    initNavigationHistoryManager() {
      this.$navigationHistoryManager.init(this.$router, this.projectRootElement)
    },
    preventTouchGestureZoom(e) {
      if (e.touches.length > 1) {
        e.preventDefault()
      }
    },
    setGoogleTagManagerIfAvailable() {
      if (this.googleTagManagerId !== null) {
        try {
          GoogleTagManager.init(this.googleTagManagerId)
        } catch (error) {
          this.$loggingService.logError(
            `Error while initializing Google Tag Manager: Id: ${
              this.googleTagManagerId
            }. Error: ${error.toString()}`
          )
        }
      }
    },
  },
}
</script>

<style lang="scss">
// FIXME: Consider moving transitions to components (e.g.: TransitionManager) instead of using global definitions
@import '~@/assets/style/main.scss';
@import '~@/assets/style/transitions.scss';

#app-container {
  position: relative;
  width: 100vw;
  height: 100vh;
}
</style>
