import AppWebFontsLoader from '@/classes/AppWebFontsLoader'
import ContactData from '@/classes/ContactData'
import ColorPallete from '@/classes/ColorPallete'
import Gallery from '@/classes/Gallery/'
import CollectionSlugs from '@/classes/Gallery/CollectionSlugs'
import sassConstants from '@/constants/sass'

const {
  defaultFontFamily,
  fontWeightLight,
  fontWeightRegular,
  fontWeightMedium,
  fontWeightBold,
} = sassConstants

export default {
  async adapterData({ commit, dispatch }, { projectData, i18n }) {
    try {
      commit('storeProjectId', projectData.project.projectId)
      commit('storeHtmlTitle', projectData.project.htmlTitle)
      commit('storeProjectBrochureUrl', projectData.project.projectBrochureUrl)
      commit(
        'storeDeveloperBrochureUrl',
        projectData.developer?.developerBrochureUrl
      )
      commit('storeProjectLocaleCodeBCP47', projectData.project.localeCodeBCP47)
      dispatch('adapterPrimaryColor', projectData.project.activeColor)
      dispatch('adapterFavicon', projectData.project.favIconImgUrl)
      dispatch('adapterLogo', projectData.menu.logo)
      dispatch('adapterHomeLogo', projectData.home.logo)
      dispatch('adapterEnterButton', projectData.home.enterButton)
      dispatch(
        'adapterIntroVimeoVideoId',
        projectData.project.introVimeoVideoId
      )
      dispatch('adapterLocation', projectData)
      dispatch('adapterContactData', projectData)
      dispatch('adapterVideoData', projectData.videoPage)
      dispatch('adapterGalleryPage', projectData.projectStructure)
      dispatch('adapterGeneralTour360Gallery', projectData.projectStructure)
      dispatch('adapterAmenitiesPage', projectData.amenitiesPage)
      dispatch('adapterFont', projectData.project.typography)
      commit('storeSvgColor', projectData.project.svgColor)
      dispatch(
        'adapterWeb3dGoogleAnalytics',
        process.env.VUE_APP_GA_TRACKING_ID
      )
      dispatch(
        'adapterCustomerGoogleAnalytics',
        projectData.project.googleAnalytics
      )
      dispatch('adapterFacebookPixel', projectData.project.facebookPixel)
      dispatch('adapterGoogleTagManager', projectData.project.googleTagManager)
      dispatch('adapterPrivacyPolicy', projectData.project.privacyPolicy)
      dispatch('adapterDisclaimer', projectData.project.contentDisclaimer)
      dispatch('adapterDeveloper', projectData.developer)
      dispatch('updateTranslationsWithCustomObject', {
        translations: projectData.customTranslations,
        i18n,
      })
      commit('setProjectType', projectData.projectStructure.type)
    } catch (err) {
      throw new Error(`In Project/adapterData: ${err.toString()}`)
    }
  },
  adapterLocation({ commit }, projectData) {
    const locationData = projectData.location
    const [lat, lng] = locationData.latLng.split(',')
    const latLng = { lat: parseFloat(lat), lng: parseFloat(lng) }
    const infoWindowContent = locationData.infoWindowContentLines
      .map((line) => line.replace(/</g, '&lt;').replace(/>/g, '&gt;'))
      .join('<br>')
    const marker = {
      imgUrl: locationData.mapMarkerImgUrl,
      // We are using the /masterplan main project image for marker info window, because its a complete image of building
      // and should be prepared for flexible sizes (like different screen resolutions), which its convenient in this case
      backgroundImgUrl:
        projectData.projectStructure.spin360.stopPoints[0].backgroundImgUrl,
      textContent: infoWindowContent,
    }
    commit('storeLocationInteractiveMapLatLong', latLng)
    commit('storeLocationInteractiveMapApiKey', locationData.apiKey)
    commit('storeLocationInteractiveMapMarkerInfo', marker)
    commit(
      'storeLocationInteractiveMapCustomMapImgUrl',
      locationData.customMapImgUrl
    )
    commit(
      'storeLocationInteractiveMapAdditionalDetails',
      locationData.additionalDetails
    )
  },
  adapterLogo({ commit }, data) {
    const logo = {
      display: data.display,
      imgUrl: data.logoImgUrl,
    }
    commit('storeLogo', logo)
  },
  adapterHomeLogo({ commit }, data) {
    const homeLogo = {
      display: data.display,
      imgUrl: data.logoImgUrl,
      style: data.embeddedCss,
    }
    commit('storeHomeLogo', homeLogo)
  },
  adapterEnterButton({ commit }, data) {
    const enterButton = {
      title: data.title,
      style: data.embeddedCss,
    }
    commit('storeEnterButton', enterButton)
  },
  adapterPrimaryColor({ commit }, color) {
    const projectColorPallete = new ColorPallete(color)
    commit('storeProjectColorPallete', projectColorPallete)
  },
  adapterFavicon({ commit }, faviconUrl) {
    commit('storeFaviconUrl', faviconUrl)
  },
  adapterContactData({ commit }, data) {
    let contact
    try {
      contact = new ContactData({
        projectName: data.contactPage.projectName || '',
        projectDescription: data.contactPage.projectDescription || '',
        developerName: data.contactPage.developerName || '',
        developerDescription: data.contactPage.developerDescription || '',
        developerMapUrl: data.contactPage.developerMapUrl || '',
        contactHtmlAdditionalDetails:
          data.contactPage.contactHtmlAdditionalDetails || '',
        emailUrl: data.project.emailUrl,
        phoneUrl: data.project.phoneUrl || '',
        whatsAppUrl: data.project.whatsAppUrl,
        instagramUrl: data.project.instagramUrl,
        facebookUrl: data.project.facebookUrl,
        emailText: data.project.email || '',
        phoneText: data.project.phoneNumber || '',
        whatsAppText: data.project.whatsAppNumber || '',
        sourceFieldOptions: data.contactPage.sourceFieldOptions || [],
      })
    } catch (error) {
      this.$loggingService.logError(
        `There was a problem trying to create a ContactData instance: ${error.toString()}`
      )
    }
    commit('storeContactData', contact)
  },
  adapterVideoData({ commit }, data) {
    commit('storeVideoData', data)
  },
  adapterGalleryPage({ commit }, projectStructureData) {
    const galleryPageGallery = new Gallery()
    try {
      projectStructureData.gallery.forEach((imageData) => {
        const { labelText, imgUrl } = imageData
        const resourceData = {
          resourceUrl: imgUrl,
          labelText,
        }
        galleryPageGallery.defaultCollection.createResource(resourceData)
      })
    } catch (error) {
      this.$loggingService.logError(
        `Project/adapterGalleryPage: cannot push image to gallery: ${error.toString()}`
      )
    }

    commit('storeGalleryPageGallery', galleryPageGallery)
  },
  adapterGeneralTour360Gallery({ commit }, projectStructureData) {
    const generalTour360Gallery = new Gallery(CollectionSlugs.TOUR360)
    try {
      projectStructureData.generalTour360Gallery.forEach((tour360) => {
        const { labelText, tour360Url } = tour360

        const resourceData = {
          labelText,
          resourceUrl: tour360Url,
        }

        generalTour360Gallery
          .collection(CollectionSlugs.TOUR360)
          .createResource(resourceData)
      })
    } catch (error) {
      this.$loggingService.logError(
        `Project/adapterGeneralTour360Gallery: cannot push tour360 to gallery: ${error.toString()}`
      )
    }

    commit('storeGeneralTour360Gallery', generalTour360Gallery)
  },
  adapterAmenitiesPage({ commit }, amenitiesPageData) {
    const amenitiesPageGallery = new Gallery()

    try {
      amenitiesPageData.gallery.forEach((imageData) => {
        const { labelText, imgUrl } = imageData
        const resourceData = {
          resourceUrl: imgUrl,
          labelText,
        }
        const createdResource = amenitiesPageGallery
          .collection(CollectionSlugs.GALLERY)
          .createResource(resourceData)

        if (imageData.animationVideoUrl) {
          createdResource.setAnimationLayerVideoUrl(imageData.animationVideoUrl)
        }
      })
    } catch (error) {
      this.$loggingService.logError(
        `Project/adapterAmenitiesPage: cannot push image to amenities gallery: ${error.toString()}`
      )
    }

    try {
      amenitiesPageData.tours360.forEach((tour360Data) => {
        const { labelText, tour360Url } = tour360Data
        const resourceData = {
          resourceUrl: tour360Url,
          labelText,
        }
        amenitiesPageGallery
          .collection(CollectionSlugs.TOUR360)
          .createResource(resourceData)
      })
    } catch (error) {
      this.$loggingService.logError(
        `Project/adapterAmenitiesPage: cannot push tour360 to amenities gallery: ${error.toString()}`
      )
    }

    commit('storeAmenitiesPageGallery', amenitiesPageGallery)
  },
  async adapterFont({ commit }, typography) {
    if (typography.fontFamilyName) {
      let fontFamily = typography.fontFamilyName
      let fontFamilyList = `'${fontFamily}', ${defaultFontFamily}`
      const localWebFonts = typography.localWebFonts
      const googleFonts = typography.googleFonts

      try {
        const appFont = new AppWebFontsLoader(fontFamily)
        if (googleFonts && googleFonts.embedUrl) {
          await appFont.addGoogleFonts(googleFonts.embedUrl)
        } else {
          await Promise.all([
            appFont.addLocalWebFontVariant(localWebFonts.lightUrl, {
              weight: fontWeightLight,
            }),
            appFont.addLocalWebFontVariant(localWebFonts.regularUrl, {
              weight: fontWeightRegular,
            }),
            appFont.addLocalWebFontVariant(localWebFonts.mediumUrl, {
              weight: fontWeightMedium,
            }),
            appFont.addLocalWebFontVariant(localWebFonts.boldUrl, {
              weight: fontWeightBold,
            }),
          ])
        }
      } catch (error) {
        this.$loggingService.logError(error.toString())
        fontFamilyList = defaultFontFamily
      }
      this.$loggingService.logMessage(`Font family set: ${fontFamilyList}`)
      commit('storeFontFamily', fontFamilyList)
    } else {
      this.$loggingService.logError('No custom web fonts set')
    }
  },
  adapterWeb3dGoogleAnalytics({ commit }, web3dGoogleAnalyticsTrackingId) {
    try {
      if (web3dGoogleAnalyticsTrackingId.trim() !== '') {
        const web3dGoogleAnalytics = {
          trackingId: web3dGoogleAnalyticsTrackingId,
        }
        commit('storeWeb3dGoogleAnalytics', web3dGoogleAnalytics)
      }
    } catch (error) {
      this.$loggingService.logError(
        `There was an error adapting Web3d Google Analytics Data: ${error.toString()}`
      )
    }
  },
  adapterCustomerGoogleAnalytics({ commit }, customerGoogleAnalytics) {
    try {
      if (typeof customerGoogleAnalytics.trackingId !== 'string') {
        throw new Error(
          `Customer Google Analytics Id received from json (${customerGoogleAnalytics.trackingId}) is not a string`
        )
      } else if (customerGoogleAnalytics.trackingId.trim() !== '') {
        commit('storeCustomerGoogleAnalytics', customerGoogleAnalytics)
      }
    } catch (error) {
      this.$loggingService.logError(
        `There was an error adapting Client's Google Analytics Data: ${error.toString()}`
      )
    }
  },
  adapterFacebookPixel({ commit }, facebookPixel) {
    try {
      if (typeof facebookPixel.pixelId !== 'string') {
        throw new Error(
          `Facebook Pixel Id received from json (${facebookPixel.pixelId}) is not a string`
        )
      } else if (facebookPixel.pixelId.trim() !== '') {
        commit('storeFacebookPixel', facebookPixel)
      }
    } catch (error) {
      this.$loggingService.logError(
        `There was an error adapting Facebook Pixel's Data: ${error.toString()}`
      )
    }
  },
  adapterGoogleTagManager({ commit }, googleTagManager) {
    try {
      if (typeof googleTagManager.id !== 'string') {
        throw new Error(
          `Google Tag Manager Id received from json (${googleTagManager.id}) is not a string`
        )
      } else if (googleTagManager.id.trim() !== '') {
        commit('storeGoogleTagManager', googleTagManager)
      }
    } catch (error) {
      this.$loggingService.logError(
        `There was an error adapting Google Tag Manager's Data: ${error.toString()}`
      )
    }
  },
  adapterPrivacyPolicy({ commit }, privacyPolicy) {
    commit('storePrivacyPolicy', privacyPolicy)
  },
  adapterDisclaimer({ commit }, disclaimer) {
    commit('storeDisclaimer', disclaimer)
  },
  setOfflineModeFlagValue({ commit, state }, isOfflineModeEnabled) {
    if (state.isOfflineModeEnabled !== undefined) {
      throw new Error(
        `Project store: Offline mode is already set as ${state.isOfflineModeEnabled}`
      )
    }
    commit('storeOfflineModeFlagValue', isOfflineModeEnabled)
  },
  adapterDeveloper({ commit }, developerData) {
    if (!developerData) {
      this.$loggingService.logError(
        'Project/adapterDeveloper: Missing developer in project JSON.'
      )
    } else if (developerData.websiteLink) {
      if (
        !developerData.websiteLink.url ||
        !developerData.websiteLink.displayText
      ) {
        this.$loggingService.logError(
          `Project/adapterDeveloper: Invalid developer.websiteLink: ${JSON.stringify(
            developerData.websiteLink
          )}.`
        )
      } else {
        commit('storeDeveloperWebsiteLink', developerData.websiteLink)
      }
    }
  },
  adapterIntroVimeoVideoId({ commit, getters }, introVimeoVideoId) {
    if (
      typeof introVimeoVideoId === 'string' &&
      introVimeoVideoId.trim() !== ''
    ) {
      if (getters.isOfflineModeEnabled) {
        this.$loggingService.logError(
          `Project/adapterIntroVimeoVideoId: introVimeoVideoId is not allowed in offline mode.`
        )
      } else {
        commit('storeIntroVimeoVideoId', introVimeoVideoId)
      }
    }
  },
  updateTranslationsWithCustomObject(_, { translations, i18n }) {
    if (!translations) {
      return
    }

    let messagesObject = i18n._vm.messages[i18n.locale]

    Object.entries(translations).forEach(([key, value]) => {
      const keys = key.split('.')

      let translateObject = messagesObject

      for (let i = 0; i < keys.length; i++) {
        if (!translateObject[keys[i]]) {
          break
        }

        if (i === keys.length - 1) {
          translateObject[keys[i]] = value
        } else {
          translateObject = translateObject[keys[i]]
        }
      }
    })
  },
}
