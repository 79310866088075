export default {
  storeProjectId(state, projectId) {
    state.projectId = projectId
  },
  storeLogo(state, val) {
    state.menu.logo = val
  },
  storeHomeLogo(state, val) {
    state.home.logo = val
  },
  storeHtmlTitle(state, title) {
    state.htmlTitle = title
  },
  storeProjectColorPallete(state, colorPallete) {
    state.projectColorPallete = colorPallete
  },
  storeEnterButton(state, val) {
    state.home.enterButton = val
  },
  storeProjectLocaleCodeBCP47(state, val) {
    state.localeCodeBCP47 = val
  },
  storeLocationInteractiveMapApiKey(state, apiKey) {
    state.location.interactiveMap.apiKey = apiKey
  },
  storeLocationInteractiveMapLatLong(state, latLng) {
    state.location.interactiveMap.configMap.center = latLng
    state.location.interactiveMap.cords[0].position = latLng
  },
  storeLocationInteractiveMapMarkerInfo(state, marker) {
    state.location.interactiveMap.marker = marker
  },
  storeLocationInteractiveMapCustomMapImgUrl(state, customMapImgUrl) {
    state.location.customMapImgUrl = customMapImgUrl
  },
  storeLocationInteractiveMapAdditionalDetails(state, additionalDetails) {
    state.location.additionalDetails = additionalDetails
  },
  storeVideoData(state, val) {
    state.videoPage = val
  },
  storeGalleryPageGallery(state, galleryPageGallery) {
    state.galleryPage = galleryPageGallery
  },
  storeGeneralTour360Gallery(state, generalTour360Gallery) {
    state.generalTour360GalleryPage = generalTour360Gallery
  },
  storeAmenitiesPageGallery(state, amenitiesPageGallery) {
    state.amenitiesPage = amenitiesPageGallery
  },
  storeSvgColor(state, val) {
    state.svgColor = val
  },
  storeFaviconUrl(state, faviconUrl) {
    state.faviconUrl = faviconUrl
  },
  storeContactData(state, val) {
    state.contact = val
  },
  storeFontFamily(state, val) {
    state.fontFamily = val
  },
  storeWeb3dGoogleAnalytics(state, web3dGoogleAnalytics) {
    state.web3dGoogleAnalytics = web3dGoogleAnalytics
  },
  storeCustomerGoogleAnalytics(state, customerGoogleAnalytics) {
    state.customerGoogleAnalytics = customerGoogleAnalytics
  },
  storeFacebookPixel(state, facebookPixel) {
    state.facebookPixel = facebookPixel
  },
  storeGoogleTagManager(state, googleTagManager) {
    state.googleTagManager = googleTagManager
  },
  storePrivacyPolicy(state, privacyPolicy) {
    state.privacyPolicy = privacyPolicy
  },
  storeDisclaimer(state, disclaimer) {
    state.disclaimer = disclaimer
  },
  storeOfflineModeFlagValue(state, isOfflineModeEnabled) {
    state.isOfflineModeEnabled = isOfflineModeEnabled
  },
  storeDeveloperWebsiteLink(state, websiteLink) {
    state.developer.websiteLink = websiteLink
  },
  setProjectType(state, projectType) {
    state.projectType = projectType
  },
  storeIntroVimeoVideoId(state, introVimeoVideoId) {
    state.introVimeoVideoId = introVimeoVideoId
  },
  storeProjectBrochureUrl(state, projectBrochureUrl) {
    state.projectBrochureUrl = projectBrochureUrl
  },
  storeDeveloperBrochureUrl(state, developerBrochureUrl) {
    state.developer.developerBrochureUrl = developerBrochureUrl
  },
}
