export default class ContactData {
  constructor(contactData) {
    this._projectName = contactData.projectName.trim()
    this._projectDescription = contactData.projectDescription.trim()
    this._developerName = contactData.developerName.trim()
    this._developerDescription = contactData.developerDescription.trim()
    this._developerMapUrl = contactData.developerMapUrl.trim()
    this._contactHtmlAdditionalDetails = contactData.contactHtmlAdditionalDetails.trim()
    this._emailUrl = contactData.emailUrl.trim()
    this._phoneUrl = contactData.phoneUrl.trim()
    this._whatsAppUrl = contactData.whatsAppUrl.trim()
    this._instagramUrl = contactData.instagramUrl.trim()
    this._facebookUrl = contactData.facebookUrl.trim()
    this._emailText = contactData.emailText.trim()
    this._phoneText = contactData.phoneText.trim()
    this._whatsAppText = contactData.whatsAppText.trim()
    this._sourceFieldOptions =
      contactData.sourceFieldOptions &&
      Array.isArray(contactData.sourceFieldOptions)
        ? contactData.sourceFieldOptions
        : []
  }

  get emailUrl() {
    return this._emailUrl
  }

  get phoneUrl() {
    return this._phoneUrl
  }

  get whatsAppUrl() {
    return this._whatsAppUrl
  }

  get instagramUrl() {
    return this._instagramUrl
  }

  get facebookUrl() {
    return this._facebookUrl
  }

  get emailText() {
    return this._emailText
  }

  get phoneText() {
    return this._phoneText
  }

  get whatsAppText() {
    return this._whatsAppText
  }

  get projectName() {
    return this._projectName
  }

  get projectDescription() {
    return this._projectDescription
  }

  get developerName() {
    return this._developerName
  }

  get developerDescription() {
    return this._developerDescription
  }

  get developerMapUrl() {
    return this._developerMapUrl
  }

  get contactHtmlAdditionalDetails() {
    return this._contactHtmlAdditionalDetails
  }

  get isWhatsappContactDataAvailable() {
    return !!this.whatsAppText && !!this.whatsAppUrl
  }

  get isDeveloperInfoAvailable() {
    return (
      !!this.developerName &&
      !!this.developerDescription &&
      !!this.developerMapUrl
    )
  }

  get sourceFieldOptions() {
    return this._sourceFieldOptions
  }
}
