<template>
  <validation-observer
    v-slot="{ invalid, valid }"
    ref="validationObserver"
    tag="form"
  >
    <p v-if="serverError" class="form-error">
      {{ serverError }}
    </p>
    <fieldset>
      <BaseInput
        ref="nameField"
        v-model="fields.name"
        :placeholder="$t('Nombre')"
        :name="$t('Nombre')"
        rules="required|max:100"
      />
      <BaseInput
        ref="emailField"
        v-model="fields.email"
        :placeholder="$t('Correo electrónico')"
        :name="$t('Correo electrónico')"
        type="email"
        rules="required|max:100"
      />
      <BaseInput
        ref="phoneNumberField"
        v-model="fields.phoneNumber"
        :placeholder="$t('Teléfono')"
        :name="$t('Teléfono')"
        type="phone"
        rules="required|max:50"
      />
      <BaseInput
        v-if="useCustomSubject"
        ref="subjectField"
        v-model="fields.subject"
        :placeholder="$t('Solicito más información para')"
        :name="$t('Asunto')"
        type="select"
        rules="required"
        :options="subjectOptions"
      />
      <BaseInput
        v-if="enableSourceFieldInContactForm"
        ref="sourceField"
        v-model="fields.source"
        :placeholder="$t('¿Por qué medio conoce el proyecto?')"
        :name="$t('Origen')"
        type="select"
        rules="required"
        :options="sourceFieldOptions"
      />
      <BaseInput
        ref="messageField"
        v-model="fields.message"
        :placeholder="$t('Mensaje')"
        :name="$t('Mensaje')"
        type="textarea"
        maxlength="500"
        rules="required|max:500"
        :field-info="$t('Máximo 500 caracteres')"
      />
    </fieldset>
    <BaseInput
      ref="policyField"
      v-model="fields.policy"
      type="checkbox"
      class="check"
      :name="$t('form.privacyPolicy')"
      :rules="{ 'require-accept': { allowFalse: false } }"
    >
      <i18n path="form.privacyPolicyCheck" tag="span">
        <template #privacyPolicy>
          <a href="#" @click.prevent="showPrivacyPolicy()">{{
            $t('form.privacyPolicy')
          }}</a>
        </template>
      </i18n>
    </BaseInput>
    <PrivacyPolicyBox v-if="isShownPrivacyPolicyBox" class="privacy-policy" />
    <AppButtonText
      type="submit"
      :text="$t('Enviar')"
      class="button"
      :disabled="invalid || preventFormSubmit"
      @click.native.prevent="submitForm(valid)"
    />
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import httpClient from '@/constants/api/http-client'
import { ValidationObserver } from 'vee-validate'
import BaseInput from '@/components/Form/BaseInput'
import AppButtonText from '@/components/UIKit/Standard/Atoms/AppButton/AppButtonText'
import PrivacyPolicyBox from '@/components/Form/PrivacyPolicyBox'
import * as contactApiEndpointNames from '@/constants/api/contact-api-endpoint-names'
import {
  filterableUnitSubjectTranslationKeys,
  generalSubjectsTranslationKeys,
} from '@/constants/contact-form-subjects-translation-keys'
import ContactApiEndpoints from '@/classes/Form/ContactApiEndpoints'

export default {
  name: 'ContactForm',
  components: {
    PrivacyPolicyBox,
    BaseInput,
    AppButtonText,
    ValidationObserver,
  },
  props: {
    modalMode: {
      type: Boolean,
      default: false,
    },
    externalSubject: {
      type: String,
      default: '',
    },
    targetApiEndpointName: {
      type: String,
      default: contactApiEndpointNames.GENERAL,
      validator: (value) => {
        return Object.values(contactApiEndpointNames).includes(value)
      },
    },
    unitId: {
      type: String,
      default: () => undefined,
    },
  },
  data() {
    return {
      fields: {
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
        subject: '',
        source: '',
        policy: false,
      },
      isShownPrivacyPolicyBox: false,
      preventFormSubmit: false,
      serverError: '',
    }
  },
  computed: {
    ...mapGetters({
      units: 'Topview/units',
      projectId: 'Project/projectId',
      contactData: 'Project/contactData',
      enableSourceFieldInContactForm:
        'Preferences/enableSourceFieldInContactForm',
    }),
    useCustomSubject() {
      return !this.modalMode
    },
    showPrivacyPolicyInForm() {
      return this.modalMode
    },
    subjectOptionsTranslationKeys() {
      const unitSubjectTranslationKeys = filterableUnitSubjectTranslationKeys
        .filter((filterableTranslateKey) => {
          const { filterParams } = filterableTranslateKey
          return this.units.some((unit) => {
            return (
              unit.type === filterParams.type &&
              unit.offerType === filterParams.offerType
            )
          })
        })
        .map((filterableTranslateKey) => filterableTranslateKey.subject)
      return [...unitSubjectTranslationKeys, ...generalSubjectsTranslationKeys]
    },
    subjectOptions() {
      return this.subjectOptionsTranslationKeys.map((value) => this.$t(value))
    },
    formTarget() {
      const apiEndpoints = new ContactApiEndpoints()
      return apiEndpoints.getEndpointUrl(this.targetApiEndpointName)
    },
    sourceFieldOptions() {
      if (this.enableSourceFieldInContactForm) {
        return this.contactData.sourceFieldOptions
      }
      return []
    },
  },
  methods: {
    handleFormSend() {
      this.preventFormSubmit = true
      this.serverError = ''

      // Shallow copy fields object to prevent possible mutation of fields before form send
      const requestData = {
        project_id: this.projectId,
        name: this.fields.name,
        email: this.fields.email,
        source: this.fields.source,
        phone_number: this.fields.phoneNumber,
        message: this.fields.message,
      }
      if (this.modalMode) {
        requestData.info = this.externalSubject
        if (this.unitId !== undefined) {
          const intUnitId = parseInt(this.unitId, 10)
          if (!intUnitId) {
            this.serverError = this.$t(
              'Ha ocurrido un error. Intenta nuevamente más tarde.'
            )
            this.preventFormSubmit = false
            throw new Error(
              `handleFormSend(): Invalid unit id ${this.unitId} (Int value: ${intUnitId}).`
            )
          }
          requestData.unit_id = intUnitId
        }
      } else {
        requestData.info = this.fields.subject
      }

      this.submitContactForm(requestData)
    },
    showPrivacyPolicy() {
      if (this.showPrivacyPolicyInForm) {
        this.isShownPrivacyPolicyBox = true
      } else {
        this.$emit('showPrivacyPolicy')
      }
    },
    async submitContactForm(requestData) {
      try {
        await httpClient.post(this.formTarget, requestData)
        this.formSubmittedSuccessfully()
      } catch (error) {
        this.$loggingService.logError(
          `Error while submitting contact form: ${error.toString}`
        )
        this.serverError = this.$t(
          'Ha ocurrido un error. Intenta nuevamente más tarde.'
        )
      } finally {
        this.preventFormSubmit = false
      }
    },
    focusFirstErrorField() {
      const orderedFieldList = [
        this.$refs.nameField,
        this.$refs.emailField,
        this.$refs.phoneNumberField,
        this.$refs.subjectField,
        this.$refs.sourceField,
        this.$refs.messageField,
        this.$refs.policyField,
      ]
      const firstErrorField = orderedFieldList
        .filter((field) => field !== undefined)
        .find((field) => field.hasErrors())
      firstErrorField.focus()
    },
    submitForm(isValid) {
      if (isValid) {
        this.handleFormSend()
      } else {
        this.$refs.validationObserver.validate().then(() => {
          this.focusFirstErrorField()
        })
      }
    },
    formSubmittedSuccessfully() {
      this.fields.subject = ''
      this.fields.message = ''
      this.fields.source = ''
      this.$refs.validationObserver.validate().then(() => {
        if (this.$refs.validationObserver) this.$refs.validationObserver.reset()
      })
      this.$emit('submit')
    },
  },
}
</script>

<style lang="scss" scoped>
.form-error {
  color: var(--form-error-color);
  font-weight: var(--font-weight-bold);
  font-size: 1rem;
  margin: 0 0 10px;
}
fieldset {
  margin-bottom: 1rem;
}
.button {
  width: 100%;
}
.check {
  margin-bottom: 2rem;
}
.box + button {
  margin-top: 11px;
}
.privacy-policy {
  margin-bottom: 2rem;
}
</style>
