import * as preferenceNames from '@/constants/preferences/preference-names'

export default {
  [preferenceNames.DISPLAY_MENU_CREDITS]: true,
  [preferenceNames.DISPLAY_AVAILABILITY]: true,
  [preferenceNames.DISPLAY_PRICE]: true,
  [preferenceNames.ENABLE_CHECK_PRICE_FORMS]: true,
  [preferenceNames.ALLOW_FULLSCREEN_NAVIGATION]: false,
  [preferenceNames.ENABLE_INTERACTIVE_BACKGROUND_ZOOM]: false,
  [preferenceNames.ENABLE_CONTACT_FORMS]: true,
  [preferenceNames.ENABLE_UNIT_PRICE_QUOTATION]: false,
  [preferenceNames.ENABLE_AVAILABILITY_VIEW]: false,
  [preferenceNames.DISPLAY_PRICES_ON_AVAILABILITY_VIEW]: false,
  [preferenceNames.ENABLE_GENERAL_TOUR360_GALLERY_VIEW]: false,
  [preferenceNames.HIDE_QUOTER_BUTTON]: false,
  [preferenceNames.USE_TYPOLOGY_FOR_AMENITIES_DESCRIPTION]: false,
  [preferenceNames.ENABLE_SOURCE_FIELD_IN_CONTACT_FORM]: false,
}
