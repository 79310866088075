export default {
  projectId: (state) => state.projectId,
  projectBrochureUrl: (state) => state.projectBrochureUrl,
  isProjectBrochureUrlAvailable: (state) => !!state.projectBrochureUrl,
  localeCodeBCP47: (state) => state.localeCodeBCP47,
  locationInteractiveMap: (state) => state.location.interactiveMap,
  locationCustomMapImgUrl: (state) => state.location.customMapImgUrl,
  locationAdditionalDetails: (state) => state.location.additionalDetails,
  isLocationAdditionalDetailsAvailable: (state) =>
    state.location.additionalDetails !== undefined,
  isLocationCustomMapAvailable: (state) =>
    state.location.customMapImgUrl !== undefined,
  logo: (state) => state.menu.logo,
  htmlTitle: (state) => state.htmlTitle,
  projectColorPallete: (state) => state.projectColorPallete,
  primaryColor: (state) => state.projectColorPallete.regular.rgbColorHexString,
  primaryColorRgb: (state) => state.projectColorPallete.regular.rgbColorObject,
  primaryColorRgba: (state) => (alpha) =>
    state.projectColorPallete.regular.makeCssRgbaColorString(alpha),
  enterButton: (state) => state.home.enterButton,
  homeLogo: (state) => state.home.logo,
  svgColor: (state) => state.svgColor,
  faviconUrl: (state) => state.faviconUrl,
  contactData: (state) => state.contact,
  projectName: (state) => state.contact.projectName,
  vimeoVideoId: (state) => state.videoPage.vimeoVideoId,
  isVideoAvailable: (state) => !!state.videoPage.vimeoVideoId,
  gallery: (state) => state.galleryPage,
  generalTour360Gallery: (state) => state.generalTour360GalleryPage,
  amenitiesGallery: (state) => state.amenitiesPage,
  isGalleryAvailable: (state) => state.galleryPage.defaultCollection.size > 0,
  isAmenitiesGalleryAvailable: (state) =>
    state.amenitiesPage.availableCollections.length > 0,
  fontFamily: (state) => state.fontFamily,
  gTagOptions: (state) => {
    const availableTrackingIds = [
      state.web3dGoogleAnalytics.trackingId,
      state.customerGoogleAnalytics.trackingId,
    ].filter((trackingId) => !!trackingId)
    const trackingIdsSet = new Set(availableTrackingIds)

    if (trackingIdsSet.size > 0) {
      const trackingIdsIterator = trackingIdsSet.values()
      const gTagOptions = {
        config: { id: trackingIdsIterator.next().value },
        includes: [...trackingIdsIterator].map((trackingId) => {
          return { id: trackingId }
        }),
      }
      return gTagOptions
    }
    return null
  },
  facebookPixelId: (state) => state.facebookPixel.pixelId,
  googleTagManagerId: (state) => state.googleTagManager.id,
  privacyPolicy: (state) => state.privacyPolicy,
  disclaimer: (state) => state.disclaimer,
  isOfflineModeEnabled: (state) => state.isOfflineModeEnabled,
  developerWebsiteLink: (state) => state.developer.websiteLink,
  developerBrochureUrl: (state) => state.developer.developerBrochureUrl,
  projectType: (state) => state.projectType,
  introVimeoVideoId: (state) => state.introVimeoVideoId,
  isIntroVimeoVideoAvailable: (state) => !!state.introVimeoVideoId,
}
