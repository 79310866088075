import Project from './Project'
import Topview from './Topview'
import DownloadManager from './DownloadManager'
import BackgroundTree from './BackgroundTree'
import Layout from './Layout'
import FeatureFlags from './FeatureFlags'
import Preferences from './Preferences'
import ModalLauncher from './ModalLauncher'
import ZoomTracker from './ZoomTracker'
import SvgInteractionTracker from './SvgInteractionTracker'
import QuoterModule from './QuoterModule'
import AvailabilityGalleryModule from './AvailabilityGalleryModule'
import EmbeddedFormsModule from './EmbeddedFormsModule'

export default {
  state: {
    transitionActive: false,
  },
  getters: {
    transitionActive: (state) => state.transitionActive,
  },
  mutations: {
    storeTransitionActive(state, val) {
      state.transitionActive = val
    },
  },
  actions: {
    setTransitionActive({ commit }, data) {
      commit('storeTransitionActive', data)
      return data
    },
    async adapterProjectJsonData(
      { dispatch },
      { projectData, i18n, isOfflineModeEnabled }
    ) {
      await dispatch('actionsBeforeModulesAdapters', {
        projectData,
        isOfflineModeEnabled,
      })
      await dispatch('modulesAdapters', { projectData, i18n })
      await dispatch('actionsAfterModulesAdapters', {
        projectData,
        i18n,
        isOfflineModeEnabled,
      })
    },
    async actionsBeforeModulesAdapters(
      { dispatch },
      { projectData, isOfflineModeEnabled }
    ) {
      await dispatch('FeatureFlags/adapterData', { projectData })
      await dispatch('Preferences/adapterData', {
        projectData,
        isOfflineModeEnabled,
      })
      try {
        dispatch('Project/setOfflineModeFlagValue', isOfflineModeEnabled)
      } catch (error) {
        this.$loggingService.logError(
          `There was a problem setting the offline mode flag value: ${error.toString()}`
        )
      }
    },
    async modulesAdapters({ dispatch }, { projectData, i18n }) {
      await dispatch('Topview/adapterData', { projectData, i18n })
      await dispatch('Project/adapterData', { projectData, i18n })
      await dispatch('EmbeddedFormsModule/adapterData', { projectData })
      await dispatch('AvailabilityGalleryModule/adapterData', { projectData })
      await dispatch('DownloadManager/adapterData', { projectData })
      await dispatch('BackgroundTree/adapterData', { projectData })
    },
    async actionsAfterModulesAdapters({ dispatch }) {
      await dispatch('Layout/createMenu')
      dispatch('DownloadManager/addTransitionsBasedOnStore')
      dispatch('BackgroundTree/addBackgroundsBasedOnStore')
    },
  },
  modules: {
    Project,
    Topview,
    DownloadManager,
    BackgroundTree,
    Layout,
    FeatureFlags,
    Preferences,
    ModalLauncher,
    ZoomTracker,
    SvgInteractionTracker,
    QuoterModule,
    AvailabilityGalleryModule,
    EmbeddedFormsModule,
  },
}
