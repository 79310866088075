export default class GoogleTagManager {
  static init(id) {
    if (!id || typeof id !== 'string' || !/^GTM-[A-Z0-9]+$/.test(id)) {
      throw new Error(
        'Google Tag Manager ID must be a string in the format GTM-XXXXXX'
      )
    }

    const script = document.createElement('script')
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${id}');`

    document.head.appendChild(script)

    const iframe = document.createElement('iframe')

    iframe.src = `https://www.googletagmanager.com/ns.html?id=${id}`
    iframe.height = 0
    iframe.width = 0
    iframe.style.display = 'none'
    iframe.style.visibility = 'hidden'

    document.body.appendChild(iframe)
  }
}
