import PointOfInterest from '@/classes/ProjectStructure/PointOfInterest'
import Gallery from '@/classes/Gallery'

export default class PointOfInterestAdapter {
  static adapt(pointOfInterestJsonData) {
    const {
      pointOfInterestId,
      svgTargetElementId,
      displayName,
      shortText,
      gallery,
      pointOfInterestSlug,
      longRichText,
      exteriorTour360,
    } = pointOfInterestJsonData

    const hasAValidGallery = Array.isArray(gallery) && gallery.length > 0

    if (hasAValidGallery) {
      const pointOfInterestGallery = new Gallery()

      gallery.forEach(({ imgUrl, animationVideoUrl }) => {
        const resourceData = {
          resourceUrl: imgUrl,
          labelText: '',
        }

        const createdResource = pointOfInterestGallery.defaultCollection.createResource(
          resourceData
        )

        if (animationVideoUrl) {
          createdResource.setAnimationLayerVideoUrl(animationVideoUrl)
        }
      })

      return new PointOfInterest({
        id: `${pointOfInterestId}`,
        svgTargetElementId,
        title: displayName,
        shortText: shortText,
        pointOfInterestSlug,
        longRichText,
        gallery: pointOfInterestGallery,
        exteriorTour360Url: exteriorTour360?.tour360Url,
      })
    }

    return new PointOfInterest({
      id: `${pointOfInterestId}`,
      svgTargetElementId,
      title: displayName,
      shortText: shortText,
      pointOfInterestSlug,
      exteriorTour360Url: exteriorTour360?.tour360Url,
    })
  }
}
