import LOCATION_CONFIG_MAP from '@/constants/location/config-map'

export default () => ({
  projectId: null,
  projectBrochureUrl: null,
  htmlTitle: '',
  projectColorPallete: null,
  localeCodeBCP47: null,
  svgColor: '#f36f21',
  faviconUrl: '',
  fontFamily: '',
  menu: {
    logo: {
      display: false,
      imgUrl: '',
    },
  },
  home: {
    enterButton: {
      title: 'EXPLORAR',
      style: {
        fontSize: '15px',
        width: '185px',
        height: '40px',
        position: 'fixed',
        bottom: '0px',
        left: '0px',
        margin: 'auto',
        right: '0px',
      },
    },
    logo: {
      display: false,
      imgUrl: '',
      style: {},
    },
  },
  location: {
    interactiveMap: {
      apiKey: '',
      marker: {
        imgUrl: '',
        backgroundImgUrl: '',
        textContent: '',
      },
      cords: [
        {
          id: '0',
          position: { lat: '', lng: '' },
        },
      ],
      configMap: LOCATION_CONFIG_MAP,
    },
    customMapImgUrl: undefined,
    additionalDetails: undefined,
  },
  contact: null,
  videoPage: {
    vimeoVideoId: '',
  },
  galleryPage: null,
  amenitiesPage: null,
  generalTour360GalleryPage: null,
  web3dGoogleAnalytics: {
    trackingId: null,
  },
  customerGoogleAnalytics: {
    trackingId: null,
  },
  facebookPixel: {
    pixelId: null,
  },
  googleTagManager: {
    id: null,
  },
  privacyPolicy: null,
  disclaimer: null,
  isOfflineModeEnabled: undefined,
  developer: {
    developerBrochureUrl: null,
    websiteLink: null,
  },
  projectType: undefined,
  introVimeoVideoId: undefined,
})
